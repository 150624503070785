import React, { useState } from 'react';
import { FaApple, FaAndroid, FaBars } from 'react-icons/fa'; // Importa le icone
import '../../assets/css/navbar.css'; // Importa il file CSS
import logo from '../../assets/img/logo.jpeg'

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <img src={logo} alt="Logo" className="navbar-logo" />
      </div>
      <div className={`navbar-content ${isMenuOpen ? 'open' : ''}`}>
        <a href="/home" className="navbar-link">Home</a>
        <a href="/cose" className="navbar-link">Cos'è</a>
        <a href="/contattaci" className="navbar-link">Contattaci</a>
        <a href="/security" className="navbar-link">Sicurezza</a>
      </div>
      <div className={`navbar-buttons ${isMenuOpen ? 'open' : ''}`}>
        <button className="navbar-button">
          <FaApple />
        </button>
        <button className="navbar-button">
          <FaAndroid />
        </button>
      </div>
      <button className="navbar-toggle" onClick={toggleMenu}>
        <FaBars />
      </button>
    </nav>
  );
};

export default Navbar;





