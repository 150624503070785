import React from 'react';
import styles from '../../assets/css/aboutsocial.module.css';

import { FaShieldAlt, FaMagic, FaUsers, FaImages, FaQuestionCircle, FaSmile, FaMobileAlt, FaShareAlt, FaRocket } from 'react-icons/fa';
import FeaturesSection from './FeatureSection';
import FAQAccordion from './FaqAccordion';

const AboutSocial = () => {
  return (
    <div className={styles.aboutSocial}>
      {/* Hero Section */}
      <div className={styles.heroSection}>
        <div className={styles.heroContent}>
          <div className={styles.textContent}>
            <h1 className={styles.title}>
              Condividi. Esplora. <br /> <span className={styles.highlight}>Anonimamente</span>.
            </h1>
            <p className={styles.description}>
              Scopri un nuovo modo di interagire su Instagram. Anonimato totale, interazioni autentiche. Unisciti alla rivoluzione social con <strong>Anonymouss</strong>.
            </p>
            <div className={styles.ctaButtons}>
              <a href="https://app.anonymouss.me" className={styles.ctaButton}>
                Scarica l'App
              </a>
              <a href="#features" className={styles.secondaryButton}>
                Scopri di più
              </a>
            </div>
          </div>
          <div className={styles.imageContent}>
            <FaMobileAlt className={styles.heroIcon} />
          </div>
        </div>
      </div>

      {/* Features Section */}
      <FeaturesSection />
          {/* Nuova Funzionalità: Novità in Arrivo */}
          

      {/* Testimonials Section */}
      <div className={styles.testimonialsSection}>
        <h2 className={styles.subtitle}>Cosa Dicono i Nostri Utenti</h2>
        <div className={styles.testimonialsGrid}>
          <div className={styles.testimonialItem}>
            <FaSmile className={styles.testimonialIcon} />
            <p>"Anonymouss ha rivoluzionato il mio modo di comunicare su Instagram!"</p>
            <span>- Marta, 24</span>
          </div>
          <div className={styles.testimonialItem}>
            <FaSmile className={styles.testimonialIcon} />
            <p>"L'anonimato mi permette di essere me stesso senza filtri. Adoro questa app!"</p>
            <span>- Luca, 19</span>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <FAQAccordion />

      {/* Call to Action Section */}
      <div className={styles.callToActionSection}>
        <h2 className={styles.ctaTitle}>Unisciti alla Rivoluzione delle Risposte Anonime</h2>
        <p className={styles.ctaDescription}>
          Scarica subito Anonymouss e scopri tutte le funzionalità che abbiamo da offrire!
        </p>
        <a href="https://app.anonymouss.me" className={styles.ctaButtonLarge}>Inizia Ora</a>
      </div>
    </div>
  );
};

export default AboutSocial;






