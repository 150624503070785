import React from 'react';
import '../../assets/css/contattaci.css'; // Importa il file CSS per gli stili

const ContattaciLayout = () => {
  return (
    <div className="contattaci">
      <h1 className="title">Contattaci</h1>
      <p className="description">Siamo qui per aiutarti! Puoi contattarci tramite i seguenti metodi:</p>
      
      <div className="contact-info">
        <div className="contact-method">
          <h2>Email</h2>
          <p><a href="mailto:support@anonymouss.me">support@anonymouss.me</a></p>
        </div>
        <div className="contact-method">
          <h2>Telefono</h2>
          <p><a href="tel:+3928882746">+39 392 888 2746</a></p>
        </div>
        <div className="contact-method">
          <h2>Indirizzo</h2>
          <p>-</p>
        </div>
        <div className="contact-method">
          <h2>Social Media</h2>
          <p>
            <a href="https://facebook.com/anonymouss" target="_blank" rel="noopener noreferrer">Facebook</a> |
            <a href="https://twitter.com/anonymouss" target="_blank" rel="noopener noreferrer"> Twitter</a> |
            <a href="https://www.instagram.com/anonymouss.app/" target="_blank" rel="noopener noreferrer"> Instagram</a>
          </p>
        </div>
      </div>

      <h2 className="subtitle">Modulo di Contatto</h2>
      <form className="contact-form">
        <label>
          Nome:
          <input type="text" name="name" required />
        </label>
        <label>
          Email:
          <input type="email" name="email" required />
        </label>
        <label>
          Messaggio:
          <textarea name="message" rows="4" required></textarea>
        </label>
        <button type="submit">Invia Messaggio</button>
      </form>
    </div>
  );
};

export default ContattaciLayout;
