import React from "react";
import Navbar from "../components/functional/Navbar";
import AboutSocial from "../components/functional/AboutSocial";
import Footer from "../components/functional/Footer";


function Home() {
    return (
        <div>
            <Navbar />
            <AboutSocial />
            <Footer />
        </div>
    );
}

export default Home;