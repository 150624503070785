import React, { useState } from 'react';
import { FaQuestionCircle, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styles from '../../assets/css/faqaccordion.module.css';

const faqs = [
  {
    question: "Come funziona l'anonimato?",
    answer:
      "La tua identità è completamente protetta grazie ai nostri sistemi di sicurezza avanzati."
  },
  {
    question: "Posso gestire più account?",
    answer:
      "Sì, puoi passare facilmente tra diversi account senza dover effettuare logout e login."
  },
  {
    question: "Come posso segnalare contenuti inappropriati?",
    answer:
      "Utilizza il pulsante di report integrato nell'app; il nostro team di moderatori, supportato dall'IA, esaminerà la segnalazione."
  },
  {
    question: "Posso cancellare o modificare un messaggio inviato?",
    answer:
      "No, per garantire l'integrità della conversazione, una volta inviato il messaggio, questo non può essere modificato o cancellato."
  },
  {
    question: "Quali sono le conseguenze per comportamenti scorretti?",
    answer:
      "Il nostro team di moderatori, supportato da strumenti di intelligenza artificiale, può sospendere o bloccare utenti che violano le regole della community."
  }
];

const FAQAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className={styles.faqSection}>
      <h2 className={styles.subtitle}>Domande Frequenti</h2>
      {faqs.map((faq, index) => (
        <div
          key={index}
          className={styles.faqItem}
          onClick={() => toggleFAQ(index)}
        >
          <div className={styles.faqQuestionContainer}>
            <FaQuestionCircle className={styles.faqIcon} />
            <h3 className={styles.faqQuestion}>{faq.question}</h3>
            <div className={styles.toggleIcon}>
              {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
          <div
            className={
              activeIndex === index
                ? `${styles.faqAnswer} ${styles.open}`
                : styles.faqAnswer
            }
          >
            <p>{faq.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQAccordion;
