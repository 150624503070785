import React, { useState, useEffect } from 'react';
import { 
  FaShieldAlt, 
  FaUsers, 
  FaMagic, 
  FaImages, 
  FaShareAlt, 
  FaRocket 
} from 'react-icons/fa';
import styles from '../../assets/css/aboutfeature.module.css';

const FeaturesSection = () => {
  // Array dei dati delle funzionalità
  const features = [
    {
      icon: <FaShieldAlt className={styles.featureIcon} />,
      title: 'Anonimato Garantito',
      description: 'La tua identità è al sicuro. Condividi liberamente senza preoccupazioni.'
    },
    {
      icon: <FaUsers className={styles.featureIcon} />,
      title: 'Gestione Multi-Account',
      description: 'Passa tra i tuoi account con un semplice tocco. Massima comodità.'
    },
    {
      icon: <FaMagic className={styles.featureIcon} />,
      title: 'Risposte Intelligenti',
      description: "L'intelligenza artificiale ti aiuta a creare risposte coinvolgenti."
    },
    {
      icon: <FaImages className={styles.featureIcon} />,
      title: 'Contenuti Multimediali',
      description: 'Invia foto, video e audio per esprimerti al meglio.'
    },
    {
      icon: <FaShareAlt className={styles.featureIcon} />,
      title: 'Reposta le Domande',
      description: 'Condividi facilmente le domande ricevute nelle tue storie Instagram.'
    },
    {
      icon: <FaRocket className={styles.featureIcon} />,
      title: 'Nuove Funzionalità in Arrivo',
      description: 'Restate sintonizzati per le prossime novità che renderanno Anonymouss ancora più eccezionale!'
    },
  ];

  const visibleCount = 3; // su desktop vengono mostrati 3 elementi contemporaneamente
  const totalSlides = features.length - visibleCount + 1; // numero di "slide" possibili (es. 6-3+1 = 4)

  // Stato per l'indice corrente del carosello
  const [currentIndex, setCurrentIndex] = useState(0);

  // Effetto per aggiornare l'indice ogni 3 secondi
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
    }, 3000);
    return () => clearInterval(interval);
  }, [totalSlides]);

  return (
    <div id="features" className={styles.featuresSection}>
      <h2 className={styles.subtitle}>Perché Scegliere Anonymouss?</h2>
      <p className={styles.sectionDescription}>
        Funzionalità innovative per un'esperienza unica.
      </p>

      {/* Carosello per Desktop */}
      <div className={styles.desktopCarousel}>
        {/* 
          Il div .featuresSlider ha display: flex e verrà traslato in base all'indice corrente. 
          Ogni elemento ha una larghezza pari a 1/3 del contenitore visibile.
        */}
        <div 
          className={styles.featuresSlider}
          style={{ transform: `translateX(-${(currentIndex * 100) / visibleCount}%)` }}
        >
          {features.map((feature, index) => (
            <div className={styles.featureItem} key={index}>
              {feature.icon}
              <h3 className={styles.featureTitle}>{feature.title}</h3>
              <p className={styles.featureDescription}>{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Lista statica per Mobile */}
      <div className={styles.mobileList}>
        {features.map((feature, index) => (
          <div className={styles.featureItem} key={index}>
            {feature.icon}
            <h3 className={styles.featureTitle}>{feature.title}</h3>
            <p className={styles.featureDescription}>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturesSection;

