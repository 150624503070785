import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ref, set, push, get, child } from "firebase/database";
import { storage, database } from '../firebase';
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import Parse from 'parse';
import axios from 'axios';

const useMessagesLogic = () => {
  const { username } = useParams();
  const [message, setMessage] = useState('');
  const [mode, setMode] = useState('text');
  const [file, setFile] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [aiImageURL, setAiImageURL] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);

  // Carica la chiave API da un file locale (es. /keys.json)
  useEffect(() => {
    fetch('/keys.json')
      .then(response => response.json())
      .then(data => setApiKey(data.openaiApiKey))
      .catch(error => console.error('Errore durante il caricamento della chiave API:', error));
  }, []);

  // Recupera il firebaseUid da Parse basato sullo username
  const fetchUIDFromParse = async (username) => {
    const query = new Parse.Query('CustomUser');
    query.equalTo('username', username);
    try {
      const results = await query.find();
      if (results.length > 0) {
        const firebaseUid = results.find(result => result.get('firebaseUid')).get('firebaseUid');
        return firebaseUid || null;
      }
      console.log('Utente non trovato');
      return null;
    } catch (error) {
      console.error('Errore durante la ricerca dell\'UID:', error);
      return null;
    }
  };

  // Recupera l'URL dell'immagine del profilo dell'utente da Firebase
  useEffect(() => {
    const fetchProfileImageUrl = async () => {
      const uid = await fetchUIDFromParse(username);
      if (uid) {
        try {
          const snapshot = await get(child(ref(database), `users/${uid}/profileImageUrl`));
          if (snapshot.exists()) {
            setProfileImageUrl(snapshot.val());
          } else {
            console.log('Nessun URL dell\'immagine del profilo trovato');
          }
        } catch (error) {
          console.error('Errore durante il recupero dell\'URL dell\'immagine del profilo:', error);
        }
      }
    };
    fetchProfileImageUrl();
  }, [username]);

  // Imposta il titolo della pagina
  useEffect(() => {
    document.title = `@${username}`;
  }, [username]);

  // Funzione per generare un canvas fingerprint
  const getCanvasFingerprint = () => {
    try {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = 200;
      canvas.height = 50;
      ctx.textBaseline = "top";
      ctx.font = "14px Arial";
      ctx.fillStyle = "#f60";
      ctx.fillRect(125, 1, 62, 20);
      ctx.fillStyle = "#069";
      ctx.fillText("fingerprint", 2, 15);
      ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
      ctx.fillText("fingerprint", 4, 17);
      return canvas.toDataURL();
    } catch (e) {
      return null;
    }
  };

  // Gestione delle modalità (text, image, video, audio, ai-image)
  const handleNextMode = () => {
    const modes = ['text', 'image', 'video', 'audio', 'ai-image'];
    setMode(modes[(modes.indexOf(mode) + 1) % modes.length]);
    setFile(null);
  };

  const handlePrevMode = () => {
    const modes = ['text', 'image', 'video', 'audio', 'ai-image'];
    setMode(modes[(modes.indexOf(mode) - 1 + modes.length) % modes.length]);
    setFile(null);
  };

  // Gestione del caricamento file
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Genera un'immagine tramite API (es. DALL-E) e ne salva l'URL
  const generateAIImage = async (prompt) => {
    if (!apiKey) {
      console.error('Chiave API non disponibile');
      return;
    }

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/images/generations',
        {
          model: 'dall-e-2',
          prompt: prompt,
          n: 1,
          size: '512x512',
          response_format: 'url'
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`
          }
        }
      );

      if (response.status === 200) {
        setAiImageURL(response.data.data[0].url);
        return response.data.data[0].url;
      } else {
        console.error('Errore durante la generazione dell\'immagine:', response.data);
      }
    } catch (error) {
      console.error('Errore durante la generazione dell\'immagine:', error.response ? error.response.data : error.message);
    }
  };

  // Genera un nome file unico per le immagini AI
  const generateUniqueFileName = () => {
    const randomId = Math.floor(Math.random() * 1e10).toString().padStart(10, '0');
    return `ai-generated-image-${randomId}.png`;
  };

  // Toggle per attivare/disattivare la modalità anonima
  const handleToggleAnonymous = () => {
    setIsAnonymous(!isAnonymous);
  };

  // Gestione dell'invio del messaggio/domanda
  const handleSubmit = async () => {
    const browserData = navigator.userAgent;
    let ipAddress = 'Indisponibile';
    try {
      ipAddress = await fetch('https://api.ipify.org?format=json')
        .then(res => res.json())
        .then(data => data.ip);
    } catch (error) {
      console.error('Errore durante il recupero dell\'IP:', error);
    }
    const browserLanguage = navigator.language || navigator.userLanguage;
    const contentRef = ref(database, 'contentData');
    const newContentRef = push(contentRef);

    // Raccolta informazioni dal browser
    const additionalDetails = {
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      viewportSize: `${window.innerWidth}x${window.innerHeight}`,
      timezoneOffset: new Date().getTimezoneOffset(),
      platform: navigator.platform,
      referrer: document.referrer,
      cookieEnabled: navigator.cookieEnabled,
      language: navigator.language,
      languages: navigator.languages,
      hardwareConcurrency: navigator.hardwareConcurrency,
      deviceMemory: navigator.deviceMemory || 'non disponibile'
    };

    // Informazioni sulla connessione (se supportate)
    if (navigator.connection) {
      additionalDetails.connection = {
        effectiveType: navigator.connection.effectiveType,
        downlink: navigator.connection.downlink,
        rtt: navigator.connection.rtt,
        saveData: navigator.connection.saveData
      };
    }

    // Dati specifici per il browser di Instagram:
    additionalDetails.instagramBrowser = /Instagram/i.test(navigator.userAgent);
    additionalDetails.fromInstagram = document.referrer.includes("instagram.com");

    // Se presente, rileva il parametro igshid dall'URL (spesso usato da Instagram)
    const igshid = new URLSearchParams(window.location.search).get("igshid");
    if (igshid) {
      additionalDetails.igshid = igshid;
    }

    // Recupera l'username IG:
    // Prova direttamente dal meta tag (anche se non è nel parametro URL)
    let igUsername = "";
    const metaIgUser = document.querySelector('meta[property="instapp:owner_user_name"]');
    if (metaIgUser) {
      igUsername = metaIgUser.getAttribute("content");
    } else {
      // Fallback: utilizza il parametro URL se disponibile
      igUsername = new URLSearchParams(window.location.search).get("ig_username");
    }
    // Se ancora non si trova, imposta un valore predefinito
    additionalDetails.igUsername = igUsername ? igUsername : "Non fornito";

    // Altre informazioni non sensibili per aumentare il fingerprinting
    additionalDetails.browserInfo = {
      appName: navigator.appName,
      appVersion: navigator.appVersion,
      vendor: navigator.vendor,
      maxTouchPoints: navigator.maxTouchPoints || 'non disponibile',
      onLine: navigator.onLine,
      doNotTrack: navigator.doNotTrack || "non disponibile"
    };

    additionalDetails.deviceFingerprint = {
      devicePixelRatio: window.devicePixelRatio,
      colorDepth: window.screen.colorDepth,
      canvasFingerprint: getCanvasFingerprint(),
      plugins: navigator.plugins ? Array.from(navigator.plugins).map(p => p.name) : [],
      mimeTypes: navigator.mimeTypes ? Array.from(navigator.mimeTypes).map(m => m.type) : [],
      hasLocalStorage: typeof window.localStorage !== 'undefined',
      hasSessionStorage: typeof window.sessionStorage !== 'undefined'
    };

    let contentData = {
      username: username,
      date: new Date().toISOString(),
      type: mode,
      message: message,
      browserData: browserData,
      ipAddress: ipAddress,
      browserLanguage: browserLanguage,
      additionalDetails: additionalDetails
    };

    // Se la modalità anonima è attiva, aggiunge il flag
    if (isAnonymous) {
      contentData.isAnonymous = true;
    }

    // Gestione del caricamento dei file (immagine, video, audio) se la modalità non è "text" o "ai-image"
    if (mode !== 'text' && mode !== 'ai-image' && file) {
      const fileStorageRef = storageRef(storage, `uploads/${file.name}`);
      await uploadBytes(fileStorageRef, file);
      const fileURL = await getDownloadURL(fileStorageRef);
      contentData = { ...contentData, fileURL: fileURL, fileName: file.name };
    }

    // Gestione per immagine generata da AI
    if (mode === 'ai-image' && aiImageURL) {
      try {
        const proxyURL = `http://localhost:5000/proxy?url=${encodeURIComponent(aiImageURL)}`;
        const response = await fetch(proxyURL);
        if (!response.ok) {
          throw new Error('Errore durante il recupero dell\'immagine dal proxy');
        }
        const blob = await response.blob();
        const fileName = generateUniqueFileName();
        const fileStorageRef = storageRef(storage, `uploads/${fileName}`);
        await uploadBytes(
          fileStorageRef,
          new File([blob], fileName, { type: 'image/png' })
        );
        const downloadURL = await getDownloadURL(fileStorageRef);
        contentData = { ...contentData, fileURL: downloadURL, fileName: fileName };
      } catch (error) {
        console.error('Errore durante il caricamento dell\'immagine AI su Firebase:', error);
      }
    }

    try {
      await set(newContentRef, contentData);
      alert('Messaggio inviato con successo!');
      setMessage('');
      setFile(null);
    } catch (error) {
      console.error('Errore durante il salvataggio del messaggio:', error);
      alert('Errore durante l\'invio del messaggio. Riprova.');
    }
  };

  return {
    username,
    message,
    setMessage,
    mode,
    setMode,
    file,
    profileImageUrl,
    aiImageURL,
    generateAIImage,
    handleNextMode,
    handlePrevMode,
    handleFileChange,
    handleSubmit,
    isAnonymous,
    handleToggleAnonymous,
  };
};

export default useMessagesLogic;















