import React from 'react';
import { 
  FaInfoCircle, 
  FaDatabase, 
  FaCogs, 
  FaLock, 
  FaShareAlt, 
  FaUserShield, 
  FaUsers, 
  FaRegEdit, 
  FaEnvelope 
} from 'react-icons/fa';
import '../../assets/css/trattamentodati.css'; // Assicurati che il path sia corretto

const TrattamentoDati = () => {
  return (
    <div className="trattamento-dati">
      <h1 className="title fadeIn">Termini, Trattamento dei Dati e Linee Guida della Community</h1>
      
      <section className="fadeIn" style={{ animationDelay: '0.2s' }}>
        <h2 className="subtitle">
          <FaInfoCircle className="icon" /> Introduzione
        </h2>
        <p className="content">
          Utilizzando la piattaforma Anonymouss, accetti i seguenti Termini e Condizioni, che includono sia il trattamento dei dati personali sia le Linee Guida della Community. Questi propositi mirano a garantire la tua sicurezza, proteggere la tua privacy e favorire un ambiente rispettoso e costruttivo per tutti.
        </p>
      </section>

      <section className="fadeIn" style={{ animationDelay: '0.4s' }}>
        <h2 className="subtitle">
          <FaDatabase className="icon" /> Raccolta dei Dati
        </h2>
        <ul className="list">
          <li>Dati di contatto (nome, indirizzo email, numero di telefono)</li>
          <li>Informazioni di accesso (username, password)</li>
          <li>Dati di utilizzo (interazioni, preferenze, attività sulla piattaforma)</li>
          <li>Contenuti generati dagli utenti (post, commenti, messaggi, risposte)</li>
        </ul>
      </section>

      <section className="fadeIn" style={{ animationDelay: '0.6s' }}>
        <h2 className="subtitle">
          <FaCogs className="icon" /> Uso dei Dati
        </h2>
        <ul className="list">
          <li>Fornire e migliorare i nostri servizi</li>
          <li>Personalizzare la tua esperienza utente</li>
          <li>Comunicare aggiornamenti, promozioni e novità</li>
          <li>Garantire la sicurezza e prevenire frodi e abusi</li>
        </ul>
      </section>

      <section className="fadeIn" style={{ animationDelay: '0.8s' }}>
        <h2 className="subtitle">
          <FaLock className="icon" /> Protezione dei Dati
        </h2>
        <p className="content">
          Adottiamo misure di sicurezza tecniche e organizzative per prevenire accessi non autorizzati, divulgazioni, alterazioni o cancellazioni dei tuoi dati. Utilizziamo protocolli di crittografia, accessi limitati e controlli periodici per mantenere elevati standard di sicurezza.
        </p>
      </section>

      <section className="fadeIn" style={{ animationDelay: '1s' }}>
        <h2 className="subtitle">
          <FaShareAlt className="icon" /> Condivisione dei Dati
        </h2>
        <p className="content">
          I tuoi dati personali non vengono venduti, noleggiati o comunicati a terzi senza il tuo esplicito consenso, eccetto nei casi previsti dalla legge o quando necessario per proteggere i diritti e la sicurezza degli utenti e della piattaforma.
        </p>
      </section>

      <section className="fadeIn" style={{ animationDelay: '1.2s' }}>
        <h2 className="subtitle">
          <FaUserShield className="icon" /> I Tuoi Diritti
        </h2>
        <p className="content">
          Hai il diritto di richiedere l'accesso, la correzione o la cancellazione dei tuoi dati personali, nonché di opporsi al loro trattamento. Per esercitare tali diritti, contattaci tramite le informazioni riportate nella sezione Contatti.
        </p>
      </section>

      <section className="fadeIn" style={{ animationDelay: '1.4s' }}>
        <h2 className="subtitle">
          <FaUsers className="icon" /> Linee Guida della Community
        </h2>
        <p className="content">
          Per garantire un ambiente sicuro e rispettoso, la piattaforma Anonymouss richiede il rispetto delle seguenti regole:
        </p>
        <ul className="list">
          <li>Rispetta la dignità e la privacy degli altri utenti: non sono ammessi insulti, molestie o comportamenti discriminatori.</li>
          <li>Non diffondere contenuti offensivi, violenti, illegali o che incitino all'odio.</li>
          <li>Usa responsabilmente lo strumento dell'anonimato: il suo abuso per diffamazione o attività illecite non sarà tollerato.</li>
          <li>Un team di moderatori, supportato da strumenti di intelligenza artificiale, monitora costantemente la piattaforma per verificare eventuali infrazioni.</li>
          <li>La funzionalità di report presente nell'app permette a chiunque di segnalare comportamenti scorretti o contenuti inappropriati.</li>
        </ul>
        <p className="content">
          Il mancato rispetto delle suddette linee guida potrà comportare la sospensione o il blocco dell'accesso alla piattaforma.
        </p>
      </section>

      <section className="fadeIn" style={{ animationDelay: '1.6s' }}>
        <h2 className="subtitle">
          <FaRegEdit className="icon" /> Modifiche alla Politica
        </h2>
        <p className="content">
          Potremmo aggiornare questa politica e i termini d'uso periodicamente. Le modifiche saranno comunicate tramite il sito e, se necessario, richiederanno il consenso esplicito degli utenti.
        </p>
      </section>

      <section className="fadeIn" style={{ animationDelay: '1.8s' }}>
        <h2 className="subtitle">
          <FaEnvelope className="icon" /> Contatti
        </h2>
        <p className="content">
          Per domande o richieste relative ai dati personali o per segnalare violazioni delle linee guida, contattaci all’indirizzo email: <a href="mailto:support@anonymouss.me">support@anonymouss.me</a>.
        </p>
      </section>
    </div>
  );
};

export default TrattamentoDati;

